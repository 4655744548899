import { useState, useMemo } from 'react';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import style from './Portfolio.module.scss';
import Button from '../../../../components/Button';

const categories = ['Все', 'Под ключ', 'Верстка'];

const myOwnDomain = 'makhnev.site';
const agencyDomain = 'newa.agency';

// Новые элементы в новом формате
const projects = [
  {
    isBest: false,
    category: 'Верстка',
    title: 'Luftbase',
    description: 'Верстка сайта',
    image: 'html/luftbase',
    link: `https://demo.${myOwnDomain}/luftbase/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'GetPort',
    description: 'Верстка сайта',
    image: 'html/getport',
    link: `https://demo.${myOwnDomain}/get-port/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Yeezypay - finance',
    description: 'Верстка сайта',
    image: 'html/yeezypay-finance',
    link: `http://demo.${myOwnDomain}/yeezypay_finance/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Pixture',
    description: 'Верстка сайта',
    image: 'html/pixture',
    link: `https://demo.${myOwnDomain}/pixture/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Will Towers',
    description: 'Верстка сайта',
    image: 'html/willtowers',
    link: 'https://willtowers.ru/',
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'SmartPay',
    description: 'SmartPay',
    image: 'html/smartpay',
    link: `https://demo.${myOwnDomain}/smartpay/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'LCS',
    description: 'LCS',
    image: 'html/lcs',
    link: `https://demo.${myOwnDomain}/lcs/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'NFTop',
    description: 'NFTop',
    image: 'html/nftop',
    link: `https://demo.${myOwnDomain}/NFTop/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Weather Check',
    description: 'Weather Check',
    image: 'vue/weathercheck',
    link: `https://demo.${myOwnDomain}/weather-vue/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Rebank',
    description: 'Rebank',
    image: 'html/rebank',
    link: `https://demo.${myOwnDomain}/rebank/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Yeezypay',
    description: 'Yeezypay',
    image: 'html/yeezypay',
    link: `https://demo.${myOwnDomain}/yeezypay/sitemap.html`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Card To Card',
    description: 'Card To Card',
    image: 'html/card-to-card',
    link: `https://demo.${myOwnDomain}/card-to-card/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Agora',
    description: 'Agora',
    image: 'html/agora',
    link: `https://demo.${myOwnDomain}/agora/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'TaxiPay',
    description: 'TaxiPay',
    image: 'html/taxipay',
    link: `https://demo.${myOwnDomain}/taxipay/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'NFT Banker',
    description: 'NFT Banker',
    image: 'html/nftbanker',
    link: `https://demo.${myOwnDomain}/nftbanker/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Fredemo',
    description: 'Fredemo',
    image: 'html/fredemo',
    link: `https://demo.${myOwnDomain}/fredemo/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Yeezypay - conf',
    description: 'Yeezypay - conf',
    image: 'html/yeezypay-conf',
    link: `http://demo.${myOwnDomain}/yeezypay_conf/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Advertex',
    description: 'Advertex',
    image: 'html/advertex',
    link: `https://demo.${myOwnDomain}/advertex/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Sports Bros',
    description: 'Sports Bros',
    type: 'react',
    image: 'react/sports-bros',
    link: `https://sportsbros.${myOwnDomain}/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Atlant',
    description: 'Atlant',
    image: 'html/atlant',
    link: `https://demo.${myOwnDomain}/inproc/sitemap.html`,
  },
  {
    isBest: true,
    category: 'Верстка',
    title: 'BotSwap',
    description: 'BotSwap',
    type: 'react',
    image: 'react/botswap',
    link: `https://botswap.${myOwnDomain}/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Zelenec',
    description: 'Zelenec',
    image: 'html/zelenec',
    link: `https://demo.${myOwnDomain}/zelenec/sitemap.html`,
  },
  {
    isBest: true,
    category: 'Верстка',
    title: 'Code Metal',
    description: 'Code Metal',
    image: 'html/metal',
    link: `http://demo.${myOwnDomain}/metal/sitemap.html`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Freedemo app',
    description: 'Freedemo app',
    type: 'react',
    image: 'react/freedemo_app',
    link: `https://freedemo.${myOwnDomain}/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Yeezypay - collab',
    description: 'Yeezypay - collab',
    type: 'react',
    image: 'react/yeezypay-collab',
    link: `https://yeezypaycollab.${myOwnDomain}/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Specokraska',
    description: 'Specokraska',
    image: 'html/specokraska',
    link: `https://demo.${myOwnDomain}/specokraska/index.html`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Authors',
    description: 'Authors',
    image: 'html/authors',
    link: `https://demo.${myOwnDomain}/authors/sitemap.html`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Paradox - landing',
    description: 'Paradox - landing',
    type: 'react',
    image: 'react/paradox-index',
    link: `https://paradox.${myOwnDomain}/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Paradox - dashboard',
    description: 'Paradox - dashboard',
    type: 'react',
    image: 'react/paradox-dashboard',
    link: `https://paradox.${myOwnDomain}/dashboard/overview`,
  },
  {
    isBest: true,
    category: 'Верстка',
    title: 'Pikamoon',
    description: 'Pikamoon',
    type: 'react',
    image: 'react/pikamoon',
    link: `https://pikamoon.${myOwnDomain}/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Pikamoon - dashboard',
    description: 'Pikamoon - dashboard',
    type: 'react',
    image: 'react/pikamoon-db',
    link: `https://pikamoon.${myOwnDomain}/dashboard/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'noaxarta',
    description: 'noaxarta',
    type: 'react',
    image: 'react/noaxarta',
    link: 'https://dev-game.pro/',
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Ballin Venture Capital',
    description: 'Ballin Venture Capital',
    type: 'react',
    image: 'react/ballin',
    link: `https://ballin.${myOwnDomain}/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Authors V2',
    description: 'Authors V2',
    image: 'html/authors_v2',
    link: `https://demo.${myOwnDomain}/authors_v2/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Boddea',
    description: 'Boddea',
    image: 'html/boddea',
    link: `https://demo.${myOwnDomain}/boddea/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Open Travel',
    description: 'Open Travel',
    image: 'html/open_travel',
    link: `https://demo.${myOwnDomain}/open_travel/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Algoquant',
    description: 'Algoquant',
    type: 'react',
    image: 'react/algoquant',
    link: `https://algoquant.${myOwnDomain}/`,
  },
  {
    isBest: true,
    category: 'Верстка',
    title: 'Rsh | Yandex',
    description: 'Rsh | Yandex',
    type: 'react',
    image: 'react/rshyandex',
    link: `https://rshyandex.${myOwnDomain}/`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'VMD',
    description: 'VMD',
    type: 'react',
    image: 'react/vmd',
    link: `https://vmd.${myOwnDomain}/`,
  },
  {
    isBest: true,
    category: 'Верстка',
    title: 'Zeno',
    description: 'Zeno',
    type: 'html',
    image: 'html/zeno',
    link: `https://demo.${myOwnDomain}/zeno/`,
  },
  {
    isBest: true,
    category: 'Верстка',
    title: 'Authors Na Bolshoy',
    description: 'Authors Na Bolshoy',
    type: 'html',
    image: 'html/authors_nabolshoy',
    link: `https://demo.${myOwnDomain}/authors-nabolshoy/sitemap.html`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'Algoquant v2',
    description: 'Algoquant v2',
    type: 'react',
    image: 'react/algoquant_v2',
    link: `https://algoperfomance.${myOwnDomain}/`,
  },
  {
    isBest: true,
    category: 'Верстка',
    title: 'Цифровой Улей',
    description: 'Цифровой Улей',
    type: 'react',
    image: 'react/ecpp',
    link: `http://ecpp.${myOwnDomain}`,
  },
  {
    isBest: true,
    category: 'Верстка',
    title: 'Tainet',
    description: 'Tainet',
    type: 'react',
    image: 'react/tainet',
    link: `http://tainet.${myOwnDomain}`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'JetTONs',
    description: 'JetTONs',
    type: 'html',
    image: 'html/jet_tons',
    link: `http://demo.${myOwnDomain}/ton-front`,
  },
  {
    isBest: false,
    category: 'Верстка',
    title: 'TonBot (Telegram App)',
    description: 'TonBot (Telegram App)',
    type: 'html',
    image: 'html/ton_bot',
    link: `http://demo.${myOwnDomain}/ton-bot`,
  },
  {
    isBest: true,
    category: 'Верстка',
    title: 'Rich Giveaway',
    description: 'Rich Giveaway',
    type: 'react',
    image: 'react/richgive',
    link: `https://richgive.${myOwnDomain}/`,
  },
  {
    isBest: true,
    category: 'Верстка',
    title: 'OTSU',
    description: 'OTSU',
    type: 'react',
    image: 'react/otsu',
    link: `http://otsu.${myOwnDomain}`,
  },
  {
    isBest: true,
    category: 'Верстка',
    title: 'TradeGuru',
    description: 'TradeGuru',
    type: 'html',
    image: 'html/trade',
    link: `http://demo.${myOwnDomain}/trade/trade.html`,
  },
  {
    isBest: true,
    category: 'Верстка',
    title: 'Sоtrаns',
    description: 'Sоtrаns',
    type: 'html',
    image: 'html/s_1',
    link: `http://demo.${myOwnDomain}/s_1/home.html`,
  },
  {
    isBest: true,
    category: 'Верстка',
    title: 'Лендинг',
    description: 'Лендинг',
    type: 'html',
    image: 'html/p_1',
    link: `http://demo.${myOwnDomain}/p_1/home.html`,
  },
  {
    isBest: true,
    category: 'Верстка',
    title: 'Coingems',
    description: 'Coingems',
    type: 'react',
    image: 'react/coin',
    link: `http://coingems.${myOwnDomain}`,
  },
  {
    isBest: false,
    category: 'Под ключ',
    title: 'Фрост А',
    description: 'Фрост А',
    image: 'turnkey/frost-a',
    link: `https://demo.${agencyDomain}/frost-a/`,
  },
  {
    isBest: false,
    category: 'Под ключ',
    title: 'Grinvel',
    description: 'Grinvel',
    image: 'turnkey/grinvel',
    link: `https://demo.${agencyDomain}/grinvel/home.html`,
  },
  {
    isBest: false,
    category: 'Под ключ',
    title: 'Черчилль',
    description: 'Черчилль',
    image: 'turnkey/cherchill',
    link: `https://demo.${agencyDomain}/cherchill/`,
  },
  {
    isBest: false,
    category: 'Под ключ',
    title: 'Казанский Кререж',
    description: 'Казанский Кререж',
    image: 'turnkey/kazansky-krepezh',
    link: `https://demo.${agencyDomain}/comfort-stroy/`,
  },
  {
    isBest: false,
    category: 'Под ключ',
    title: 'RTSP',
    description: 'RTSP',
    image: 'turnkey/rtsp',
    link: 'https://demo.newa.agency/rts_p/',
  },
];

const PortfolioSection = () => {
  const [activeCategory, setActiveCategory] = useState('Все');
  const [visibleProjects, setVisibleProjects] = useState(6);

  // Делаем копию массива и разворачиваем её (чтобы не мутировать исходный массив)
  const reversedProjects = useMemo(() => [...projects].reverse(), []);
  const filteredProjects = reversedProjects.filter((project) =>
    activeCategory === 'Все' ? true : project.category === activeCategory
  );

  const displayedProjects = filteredProjects.slice(0, visibleProjects);
  const hasMoreProjects = visibleProjects < filteredProjects.length;

  const handleLoadMore = () => {
    setVisibleProjects((prev) => Math.min(prev + 6, filteredProjects.length));
  };

  const getCategoryIcon = (category) => {
    switch (category) {
      case 'Все':
        return (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='lucide lucide-briefcase w-4 h-4'
          >
            <rect width='20' height='14' x='2' y='7' rx='2' ry='2'></rect>
            <path d='M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16'></path>
          </svg>
        );
      case 'Верстка':
        return (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='lucide lucide-code w-4 h-4'
          >
            <polyline points='16 18 22 12 16 6'></polyline>
            <polyline points='8 6 2 12 8 18'></polyline>
          </svg>
        );
      case 'Под ключ':
        return (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='size-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605'
            />
          </svg>
        );
      default:
        return (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='lucide lucide-briefcase w-4 h-4'
          >
            <rect width='20' height='14' x='2' y='7' rx='2' ry='2'></rect>
            <path d='M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16'></path>
          </svg>
        );
    }
  };

  return (
    <section id='projects' className={style.portfolio}>
      <div className='__container'>
        <Title h1 center>
          Наши проекты
        </Title>
        <div className={style.portfolioFilter}>
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setActiveCategory(category)}
              className={`${style.portfolioFilter__item} ${
                activeCategory === category ? style.active : ''
              }`}
            >
              {getCategoryIcon(category)}
              <Text md black>
                {category}
              </Text>
            </button>
          ))}
        </div>
        <div className={style.portfolioBody}>
          {displayedProjects.map((project) => (
            <a
              href={project.link}
              target='_blank'
              rel='noreferrer'
              className={style.portfolioCard}
              key={project.id}
            >
              <div className={style.portfolioCard__tip}>
                <Text md>Перейти на сайт</Text>
              </div>
              <div className={style.portfolioCard__wrapper}>
                <div className={style.portfolioCard__img}>
                  <picture>
                    <source
                      type='image/webp'
                      srcSet={`./assets/img/${project.image}.webp`}
                    />
                    <source
                      type='image/jpeg'
                      srcSet={`./assets/img/${project.image}.jpg`}
                    />
                    <img
                      src={`./assets/img/${project.image}.jpg`}
                      alt={project.title}
                    />
                  </picture>
                  <div className={style.portfolioCard__imgLabel}>
                    <Text md black fz16>
                      {project.category}
                    </Text>
                  </div>
                </div>
                <div className={style.portfolioCard__content}>
                  <Title h3 black className={style.portfolioCard__contentTitle}>
                    {project.title}
                  </Title>
                  {/* <Text md black className={style.portfolioCard__contentText}>
                    {project.description}
                  </Text> */}
                </div>
              </div>
            </a>
          ))}
        </div>
        {hasMoreProjects && (
          <Button
            lg
            className={style.portfolio__more}
            onClick={handleLoadMore}
            title={'Показать еще'}
          />
        )}
      </div>
    </section>
  );
};

export default PortfolioSection;
